.categories {
  max-width: 992px;
  margin: 0 auto;

  .container {
    padding-left: 16px;
    padding-right: 16px;

    @media (min-width: 768px) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &-row {
    margin-left: -8px;
    margin-right: -8px;

    [class^='col'] {
      padding-left: 8px;
      padding-right: 8px;
      margin-bottom: 16px;
    }
  }
}
