.goals {
  max-width: 873px;
  margin: 0 auto;
  margin-bottom: 24px;
  display: none;

  &-left {
    width: 292px;
    min-width: 292px;
  }

  &-list {
    display: flex;
    flex: 1;
    justify-content: space-around;
  }

  @media (min-width: 768px) {
    display: flex;
  }
}

.goal {
  flex: 1;
  text-align: center;

  &-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
    width: 20%;
    flex-basis: 20%;

    &:focus,
    &:active {
      outline: none;
    }

    img {
      display: none;
    }

    &-title {
      color: #000;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
    }

    &-tooltip {
      border: none;
      background: none;
      margin-left: 4px;
      position: relative;

      &-content {
        position: absolute;
        background: #ffffff;
        padding: 8px;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
        z-index: 10;
        right: 0;
        top: 30px;
        display: flex;
        align-items: flex-start;
        width: 300px;
        min-width: 200px;
        border-radius: 4px;
        opacity: 0;
        visibility: hidden;
        transition: all 0.2s ease-in-out;

        img {
          position: relative;
          top: 4px;
        }

        div {
          padding-left: 8px;
          text-align: left;
          font-size: 14px;
        }
      }

      &:hover {
        .goal-button-tooltip-content {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    @media (min-width: 768px) {
      background: none;

      img {
        display: block;
      }

      &-title {
        color: #fff;
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
      }

      &-tooltip {
        img {
          width: initial;
          height: initial;
        }
      }
    }
  }

  &-btn-toggle {
    @media (max-width: 768px) {
      border: 1px solid #000;
      color: #000;
      width: 100%;

      &.is--active {
        background-color: #f5f5f5;
        color: #000;
      }
    }
  }
}
