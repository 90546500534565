.welcome {
  @media (max-width: 768px) {
    &-form {
      width: 100%;
    }
  }

  &-btn {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    font-family: 'Quicksand', sans-serif;
    text-transform: uppercase;

    @media (max-width: 768px) {
      &-continue {
        width: 100%;
        justify-content: center;
      }
    }
  }

  @media (max-width: 768px) {
    &-form-group {
      margin: 0;
      width: 100%;
    }
  }

  &-input {
    width: 100% !important;
    margin: 0;

    @media (min-width: 768px) {
      width: 350px !important;
    }
  }

  &-card {
    padding: 24px 8px;
    max-width: 700px;
    width: 100%;
    margin: 0 auto;

    @media (min-width: 768px) {
      padding: 64px;

      h1 {
        text-align: center;
      }
    }
  }
}
