.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
  margin-top: 1rem;

  &-logos {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;

    a {
      margin: 0 4px;
    }
  }
}
